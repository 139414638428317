<template>
	<div class="notice-wrap">
		<div class="service_item">
			<img src="../../assets/images/oversea/1.jpg" />
			<img src="../../assets/images/oversea/2.jpg" />
			<img src="../../assets/images/oversea/3.jpg" />
			<img src="../../assets/images/oversea/4.jpg" />
			<img src="../../assets/images/oversea/5.jpg" />
			<img src="../../assets/images/oversea/6.jpg" />
			<img src="../../assets/images/oversea/7.jpg" />
			<img src="../../assets/images/oversea/8.jpg" />
		</div>
		<div class="pop_button">
			<img src="../../assets/images/oversea/button.jpg" @click="dialogTableVisible=true" />
			<div class="line"></div>
		</div>
		<el-dialog title="意见反馈" width="600px" :visible.sync="dialogTableVisible" center>
			<el-form ref="form" :model="form">
		   <div class="form_item">企业名称：</div>
		   <el-input v-model="form.company_name" placeholder="请输入内容"></el-input>
		   <div class="form_item">所属行业：</div>
		   <el-input v-model="form.area" placeholder="请输入内容"></el-input>
		   <div class="form_item">想要咨询的服务：</div>
		   <el-select v-model="form.service" placeholder="请选择" style="width:100%;">
		       <el-option
		         v-for="item in service_options"
		         :key="item"
		         :label="item"
		         :value="item">
		       </el-option>
		     </el-select>
		   <div class="form_item">联系电话：</div>
		   <el-input v-model="form.contact" placeholder="请输入内容"></el-input>
		   <div class="form_item">备注：</div>
		   <el-input
		     type="textarea"
		     :rows="4"
		     placeholder="请输入内容"
		     v-model="form.memo">
		   </el-input>
			<div style="padding-top:20px;text-align: center;">
		       <el-button type="primary" @click="submitForm('form')">确 定</el-button>
			</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import http from "../../utils/http"

/**
 * 提交留言反馈
 */
export function submit_feedback(params) {
    return http({
        url: "/api/purchase/feedback",
        data: params
    })
}

export default {
  name: "list",
  data: () => {
    return {
		purchaseList:[],
		total: 0,
		currentPage: 1,
		pageCount:1,
		pageSize: 12,
		loading:true,
		dialogTableVisible:false,
		centerDialogVisible:true,
		filters: {
		    order: "id",
		    sort: "desc"
		},
		service_options:[
			'国际物流海外仓储',
			'出口保理',
			'国际法务',
			'质量检测',
			'知识产权',
			'招标服务培训',
			'报关结汇退税',
			'供应链金融'
		],
		form:{
			company_name:'',
			area:'',
			service:'',
			contact:'',
			memo:''
		}
	};
  },
  methods: {
	submitForm(formName) {
		if(this.form.company_name==''){
			alert('请输入公司名称！');
			return false;
		}
		if(this.form.area==''){
			alert('请输入所属行业！');
			return false;
		}
		if(this.form.service==''){
			alert('请输入想要咨询的服务！');
			return false;
		}
		if(this.form.contact==''){
			alert('请输入联系电话！');
			return false;
		}
		if(this.form.memo==''){
			alert('请输入备注！');
			return false;
		}
		submit_feedback(this.form)
		.then(res => {
		    if (res.code == 0) {
				this.dialogTableVisible=false
				this.$alert('我们将在3个工作日内，\n提供一对一专属咨询服务，请您耐心等待！', '感觉您支持外联采！', {
					confirmButtonText: '确定',
					callback: action => {
						
					}
				});
		    }
		})
		
	}
  }
};
</script>
<style lang="scss" scoped>
.form_item{font-size:16px;padding-top:10px;}
.notice-wrap {
	margin-top:22px;
	padding-bottom:30px;
	background: #ffffff;
	overflow:hidden;
}
.service_item{width:100%;display: flex;justify-content:space-between;flex-wrap:wrap;flex-direction:row;}
.service_item img{width:592px;margin-bottom:16px;}
.pop_button{text-align: center;padding-top:20px;position:relative;z-index:100;}
.pop_button div.line{width:100%;height:3px;background:#666;position:absolute;bottom:22px;z-index:-1;}
</style>
